//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModal: () => import("@/components/XModal"),
    progresscom: () => import("./progressCom"),
  },
  props: {
    //是否显示截图
    IsRealTimeScreenshot: {
      type: Boolean,
      default: true,
    },
    selActiveTime: {
      type: String,
      default: null,
    },
    teamValue: {
      type: Number,
      default: null,
    },
    //开始的日期
    stime: {
      type: String,
      default: null,
    },
    //结束的日期
    etime: {
      type: String | Date,
      default: null,
    },
    //用户id
    uid: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      pageData: {
        pageIndex: 1,
        pageSize: 20,
        totalNum: 0,
      }, //分页
      loading: false,
      tableData: [], //软件表格数据
      selRow: null, //选择的窗口
    };
  },
  methods: {
    handleWindowKey(row) {
      this.selRow = {
        name: row.Key,
      };
      this.$nextTick(() => {
        this.$modal.show("allfoftwarepic");
      });
    },
    /**
     * 打开弹窗回调
     */
    opened() {
      this.$nextTick(() => {
        this.getAllData();
      });
    },
    /**
     * 获取全部软件数据
     */
    getAllData() {
      const data = {
        p: this.pageData.pageIndex,
        c: this.pageData.pageSize,
        stime: this.selActiveTime
          ? this.selActiveTime.timeFormat("yyyy-MM-dd 00:00:01")
          : this.stime,
        etime: this.selActiveTime
          ? this.selActiveTime.timeFormat("yyyy-MM-dd 23:59:59")
          : this.etime,
        u: this.uid,
        teamId: this.teamValue,
      };
      this.loading = true;
      this.$http
        .get("/Teams/MemberJob/AppDetails.ashx", {
          params: data,
        })
        .then((resp) => {
          if (resp.res == 0) {
            if (resp.data && resp.data.data.length) {
              //树形表格添加id
              resp.data.data.forEach((e, index) => {
                e.haschil = true;
                e.rid = index + 1;
                if (e.Form && e.Form.length) {
                  e.Form.forEach((s, ind) => {
                    s.rid = `${index + 1}-${ind}`;
                  });
                }
              });
            }
            this.tableData = resp.data.data;
            this.pageData.totalNum = resp.data.total;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 每页条数改变
     */
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.pageData.pageIndex = 1;
      this.getAllData();
    },
    /**
     * 页数改变
     */
    handleCurrentChange(val) {
      this.pageData.pageIndex = val;
      this.getAllData();
    },

    attenceHeaderStyle() {
      return {
        color: "#909399",
      };
    },
  },
};
